import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Button from '~components/Button'
import Image from '~components/Image'
import SanityLink from '~components/SanityLink'
import Slider from '~components/Slider'
import CursorPanel from '~components/CursorPanel'

const Slide = ({ className, content }) => {
	const [hover, setHover] = useState(false)

	return (
		<SlideWrap 
			className={className} 
		>
			<LinkContainer link={content.button?.link}>
				<Container
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
				>
					<Hover cursorMessage={'View Story'}/>
					<FloatingContainer show={hover}>
						<FloatingImage image={content.image}/>
					</FloatingContainer>
					<Date>{content.date}</Date>
					<Title className='h1-serif'>{content.title}</Title>
					<Button noLink>{content.button.text}</Button>
				</Container>
			</LinkContainer>
		</SlideWrap>
	)
}

const SlideWrap = styled.div`
	text-align: center;
	width: 100%;
	display: flex;
	align-items: center;
	padding: var(--xl) 0;
	${mobile}{
		padding: var(--m-xl) 0 0; 
	}
`
const LinkContainer = styled(SanityLink)`
	max-width: 745px;
	margin: 0 auto;
	padding: 0 25px;
	position: relative;
`
const Container = styled.div`

`
const Hover = styled(CursorPanel)`
	left: 0;
	right: 0;
`
const FloatingContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events:${props => props.show ? 'all' : 'all'} ;
	opacity: ${props => props.show ? '1' : '0'};
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity 0.25s;
	z-index: -1;
	${mobile}{
		display: none;
	}
`
const FloatingImage = styled(Image)`
	width: 484px;
`
const Date = styled.div`
	margin-bottom: 48px;
	${mobile}{
		margin-bottom: 38px;
	}
`
const Title = styled.div`
	margin-bottom: 48px;
	${mobile}{
		margin-bottom: 38px;
	}
`

const FeaturedStories = ({ className, stories }) => {

	return (
		<Wrap className={className} >
			<Slider totalSlides={stories?.length} centered>
				{stories.map(story => (
					<Slide content={story} key={story._key}/>
				))}
			</Slider>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: relative;
	${mobile}{
		margin-bottom: var(--m-xl);
	}
`
FeaturedStories.propTypes = {
	className: PropTypes.string,
	stories: PropTypes.array,
}

Slide.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}
export default FeaturedStories