import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Button from '~components/Button'
import Section from '~components/Section'
import * as Flickity from 'react-flickity-component'
import '~styles/flickity.css'
import Image from '~components/Image'
import SanityLink from '~components/SanityLink'
import Slider from '~components/Slider'
import CursorPanel from '~components/CursorPanel'

const Slide = ({className, content}) => {
	const videoRef = useRef()

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current?.play()
		}
	}, [videoRef])

	return (
		<Project 
			className={className}
		>
			<LinkContainer 			
				link={{
					linkType: 'internal',
					document: content.project
				}}
			>
				<Hover cursorMessage={content?.project?.title}/>
				{content.contentType === 'video' ? 
					<Video playsInline={true} ref={videoRef} src={content.video?.video?.asset?.url} loop muted/>
					:
					<SlideImage image={content.image}/>
				}
			</LinkContainer>
		</Project>
	)
}

const Project = styled.div`
	width: 66%;
	margin-right: 25px;
	${mobile}{
		width: 80%;
		margin-right: 20px;
	}
`
const LinkContainer = styled(SanityLink)`
	line-height: 0;
`
const Video = styled.video`
	width: 100%;
	object-fit: cover;
`
const SlideImage = styled(Image)`
`
const Hover = styled(CursorPanel)`
	width: 100%;
`

const ProjectSlider = ({ className, content }) => {

	const [dragging, setDragging] = useState()

	return (
		<>
			<Wrap className={className}>
				<Header>
					<Title className='h2-sans'>{content.title}</Title>
					{content.link.text && <ButtonDesk link={content.link.link}>{content.link.text}</ButtonDesk>}	
				</Header>
				<Body>
					<StyledSlider 
						totalSlides={content.projects.length}
						onDragging={value => setDragging(value)}
						button={content.link}
						featuredProjects
					>
						{content.projects.map(project => (
							<Slide content={project} key={project._key} css={css`pointer-events: ${dragging ? 'none' : 'all'};`}/>
						))}
					</StyledSlider>
				</Body>	
				<Section><Line /></Section>
			</Wrap>
		</>
	)
}	

const Wrap = styled.div`
	overflow: hidden;
`
const Header = styled.div`
	display: grid;
	grid-template-columns: 1fr max-content;
	grid-column-gap: 25px;
	padding: var(--s) 25px 52px;
	${mobile}{
		padding: var(--m-s) 20px;
		grid-template-columns: 1fr;
	}
`
const Title = styled.div`
`
const ButtonDesk = styled(Button)`
	${mobile}{
		display: none;
	}
`
const StyledSlider = styled(Slider)`
	padding: 0 25px;
	.flickity-viewport {
		overflow: visible;
	}
	${mobile}{
		padding: 0 20px;
		margin-bottom: 40px;
	}
`
const Body = styled.div`
	margin-bottom: var(--l);
	${mobile}{
		margin-bottom: var(--m-l);
	}
`
const Line = styled.div`
	border-bottom: 1px solid var(--black);
	grid-column: span 16;
`

ProjectSlider.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

Slide.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default ProjectSlider