import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import useSiteSettings from '~utils/useSiteSettings'
import Section from '~components/Section'
import HeaderSlider from '~components/HeaderSlider'
import RichText from '~components/RichText'
import Button from '~components/Button'
import ProjectSlider from '~components/ProjectSlider'
import FeaturedStories from '~components/FeaturedStories'
import { mobile, tablet } from '~styles/global'
import { useState } from 'react'
import { Collapse } from 'react-collapse'


const IndexPage = ({ data }) => {
	const { page } = data
	const [isOpen, setIsOpen] = useState(false)

	return (
		<>	
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<HeaderSlider slides={page.slider}/>
			<Intro>
				<IntroBlock>
					<IntroText className='h1-serif'>
						<RichText content={page.introText} transformParagraphs='h1'/>
					</IntroText>
					<Collapse isOpened={isOpen}>
						<IntroSubtext>
							<RichText content={page.introSubtext}/>
						</IntroSubtext>
					</Collapse>
					<div>
						{isOpen ? 
							<IntroButton link={page.introLink.link}>
								{page.introLink?.text}
							</IntroButton>
							:
							<SeeMore onClick={() => setIsOpen(true)}>
								<Button noLink down>
									See More
								</Button>
							</SeeMore>	
						}
					</div>
				</IntroBlock>
			</Intro>
			<Section><Line /></Section>
			{page.projectSliders.map(slider => (
				<ProjectSlider key={slider._key} content={slider}/>
			))}
			<FeaturedStories stories={page.featuredStories}/>
			<DataViewer data={page} name="page"/>
		</>
	)
}

const Intro = styled(Section)`
	> div {
		align-items: center;
	}
`
const IntroBlock = styled.div`
	grid-column: span 16;
	max-width: 1020px;
	display: grid;
	text-align: center;
	margin: 0 auto;
	padding: var(--xl) 0;
	${mobile}{
		grid-column: 3/15;
		padding: var(--m-xl) 0;
	}
`
const IntroText = styled.div`
	margin-bottom: 44px;
	${mobile}{
    margin-bottom: 44px;
	}
`
const IntroSubtext = styled.div`
	max-width: 922px;
	margin: 0 auto;
	padding-bottom: 44px;
	${mobile}{
    padding-bottom: 44px;
	}
`
const SeeMore = styled.button`
	
`
const IntroButton = styled(Button)`
`
const Line = styled.div`
	border-bottom: 1px solid var(--black);
	grid-column: span 16;
`

export const query = graphql`
  query HomeQuery {
    page: sanityHomePage {
      title
			seo{
				...seo
			}
			slider {
				slideType
				title
				_key
				image {
					...imageWithAlt
				}
				imageMobile {
					...imageWithAlt
				}
				video {
					...video
				}
				videoMobile {
					...video
				}
				link {
					...link
				}
				cursorText
			}
			introText: _rawIntroText(resolveReferences: {maxDepth: 5})
			introSubtext: _rawIntroSubtext(resolveReferences: {maxDepth: 5})
			introLink {
				...button
			}
			projectSliders {
				title
				link {
					...button
				}
				_key
				projects {
					contentType
					image {
						...imageWithAlt
					}
					video {
						...video
					}
					project {
						_type
						title
						slug {
							current
						}
					}
				}
			}
			featuredStories {
				title
				date
				image {
					...imageWithAlt
				}
				_type
				_key
				button {
					...button
				}
			}
    }
  }
`

IndexPage.propTypes = {
	data: PropTypes.object,
}

export default IndexPage
