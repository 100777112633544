import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, mobileUp } from '~styles/global'
import Image from '~components/Image'
import '~styles/flickity.css'
import CursorPanel from '~components/CursorPanel'
import SanityLink from '~components/SanityLink'
import useBreakpoint from '~utils/useBreakpoint'
import { Helmet } from 'react-helmet'
import canUseDOM from '~utils/canUseDOM'

const Slide = ({ className, slide }) => {

	const [initialWindowHeight, setInitialWindowHeight] = useState()

	useEffect(() => {
		setInitialWindowHeight(window.innerHeight)
	}, [])

	// console.log(initialWindowHeight)

	const { isMobile } = useBreakpoint()

	return (
		<SlideWrap 
			className={className}
			windowHeight={initialWindowHeight}
		>
			<SlideLink link={slide.link}>
				<SlideCursor color={'var(--black)'} cursorMessage={slide.cursorText}/>
				<Media>
					{slide.slideType === 'video' ? 
						<>
							{(slide.videoMobile && isMobile) ? 
								<VideoMobile playsInline={true} autoPlay={true} muted loop windowHeight={initialWindowHeight}>
									<source src={slide.videoMobile?.video?.asset?.url} type='video/webm;codecs="vp8, vorbis"'/>
									<source src={slide.videoMobile?.video?.asset?.url} type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'/>
									Your browser does not support the video tag.
								</VideoMobile>
								:
								<Video src={slide.video?.video?.asset?.url} loop muted autoPlay={true}/>
							}
						</>	
						:
						<>
							{(slide.imageMobile && isMobile) ? 
								<SlideImageMobile cover image={slide.imageMobile} windowHeight={initialWindowHeight}/>
								:
								<SlideImage cover image={slide.image}/>
							}
						</>
					}
				</Media>
			</SlideLink>
		</SlideWrap>
	)
}

const SlideWrap = styled.div`
	height: 100vh;
	width: 100%;
	${mobile}{
		height: ${props => props.windowHeight + 'px'};
	}
`
const SlideLink = styled(SanityLink)`
	
`
const SlideCursor = styled(CursorPanel)`
	width: 100%;
`
const Media = styled.div`
`
const Video = styled.video`
	height: 100vh;
	width: 100%;
	object-fit: cover;
`
const VideoMobile = styled.video`
	width: 100%;
	object-fit: cover;
	height: ${props => props.windowHeight + 'px'};
`
const SlideImage = styled(Image)`
	height: 100vh;
	width: 100%;

	img {
		object-fit: cover;
	}
`
const SlideImageMobile = styled(Image)`
	width: 100%;
	height: ${props => props.windowHeight + 'px'};
	img {
		object-fit: cover;
	}
`

const HeaderSlider = ({ className, slides }) => {
	
	const { isMobile } = useBreakpoint()
	const flkty = useRef()
	const flickityEl = useRef()

	const [currentSlide, setCurrentSlide] = useState(0)
	const [dragging, setDragging] = useState(false)
	const [init, setInit] = useState()

	useEffect(() => {
		if(canUseDOM && init){
			const Flickity = require('flickity')
			flkty.current = new Flickity(flickityEl.current, flickityOptions)
		}
		setInit(true)
	}, [isMobile])

	useEffect(() => {
		if (canUseDOM && (!isMobile && init)){
			const Flickity = require('flickity')
			flkty.current = new Flickity(flickityEl.current, flickityOptions)
		}
	}, [init])

	const flickityOptions = {
		prevNextButtons: false,
		pageDots: false,
		adaptiveHeight: false,
		wrapAround: true,
		cellAlign: 'left',
		freeScroll: false,
		contain: true,
		draggable: isMobile ? true : false,
		autoPlay: 5000,
	}

	useEffect(() => {
		if (flkty && init) {
			flkty?.current?.on('change', () => {
				setCurrentSlide(flkty?.current?.selectedIndex)
			})
		}
	}, [flkty, init])

	useEffect(() => {
		if(flkty && init){
			flkty?.current?.on('dragStart', function(){
				setDragging(true)
			})
			flkty?.current?.on('dragEnd', function(){
				setDragging(false)
			})
		}
	}, [flkty, init])


	const nextSlide = () => {
		flkty?.current?.next()
	}

	const prevSlide = () => {
		flkty?.current?.previous()
	}

	const addZero = n => {
		return (n < 10 ? '0' : '') + n
	}

	return (
		<>
			<Helmet>
				<script src="https://unpkg.com/flickity@2/dist/flickity.pkgd.min.js"></script>
			</Helmet>
			<Wrap className={className}>
				<PrevPanel onClick={() => prevSlide()} cursorMessage={'Previous'} color={'var(--red)'} />
				<NextPanel onClick={() => nextSlide()} cursorMessage={'Next'} color={'var(--yellow)'} />
				<div ref={flickityEl}>
					{slides.map((slide, index) => (
						<Slide 
							slide={slide} key={slide._key} 
							index={index + 1} 
							total={slides.length} 
							css={css`pointer-events: ${dragging ? 'none' : 'all'};`}
						/>
					))}
				</div>
				<Details>
					<Index>
						{addZero(currentSlide + 1)}/{addZero(slides?.length)}
					</Index>
					<Title>
						{slides[currentSlide].title}
					</Title>
				</Details>
			</Wrap>
		</>
		
	)
}

const Wrap = styled.div`
	height: 100vh;
	position: relative;
	${mobile}{
		height: auto;
	}
`
const PrevPanel = styled(CursorPanel)`
	width: 33.33%;
	left: 0;
`
const NextPanel = styled(CursorPanel)`
	width: 33.33%;
	right: 0;
`

const Details = styled.div`
	position: absolute;	
	bottom:	24px;
	left: 0;
	right: 0;
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	padding: 0 25px;
	grid-column-gap: 20px;
	${mobile}{
		padding: 0 20px;
		grid-template-columns: 1fr;
		width: calc(100% - 78px);
    box-sizing: border-box;
		bottom:	20px;
	}
`
const Index = styled.div`
	${mobile}{
		margin-bottom: 6px;
	}
`
const Title = styled.div`
	
`
HeaderSlider.propTypes = {
	className: PropTypes.string,
	slides: PropTypes.object,
}

Slide.propTypes = {
	className: PropTypes.string,
	slide: PropTypes.object,
	index: PropTypes.number,
	total: PropTypes.number,
}

export default HeaderSlider